import React from "react"
import { Link } from "gatsby"
import Button from "react-bootstrap/Button"
import { createLink } from "../utils"
import arrow from "../images/chevron.svg"

const LinkButton = ({
  variant = "secondary",
  size = "lg",
  href,
  className,
  title,
  children,
  arrowIcon = false,
  CustomIcon = null,
}) => {
  const Content = React.useMemo(() => {
    return (
      <>
        {title ?? children}
        {arrowIcon ? (
          <img
            src={arrow}
            alt=""
            className="ml-3"
            width={14}
            height={16}
            style={{ transform: "translateY(-1px)" }}
          />
        ) : null}
        {CustomIcon}
      </>
    )
  }, [children, title, arrowIcon, CustomIcon])

  const link = createLink(href)

  if (link.startsWith("http")) {
    return (
      <Button
        variant={variant}
        size={size}
        href={link}
        className={"px-4 py-3" + `${className ? ` ${className}` : ""}`}
      >
        {Content}
      </Button>
    )
  }

  return (
    <Button
      variant={variant}
      size={size}
      className={"px-4 py-3" + `${className ? ` ${className}` : ""}`}
    >
      <Link to={link} className="text-decoration-none text-white">
        {Content}
      </Link>
    </Button>
  )
}

export { LinkButton }

import React, { memo } from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { graphql, useStaticQuery } from "gatsby"
import Moment from "moment"
import { filterPostsByCategories, prioritisePosts } from "../../utils"
import style from "./LatestPosts.module.scss"

import { Link } from "../Link"
import { LinkButton } from "../LinkButton"

export const PostCard = ({ title, href, dt, mainImage, slug }) => {
  return (
    <div className={`card mb-4 ${style.box}`}>
      <Link href={href} className={`mx-auto p-0 ${style.headerLink}`}>
        {mainImage !== undefined ? (
          <img
            src={mainImage.sourceUrl}
            alt={mainImage.title}
            className={`mx-auto img-fluid  ${style.mainImage}`}
          />
        ) : (
          console.error(
            "ERROR required main image missing in Latest Posts",
            slug
          )
        )}
      </Link>
      <div className={`card-body d-flex flex-column`}>
        <span className={`text-left h4 ${style.date}`}>{dt}</span>
        <h3 className={style.header}>
          <Link href={href} className={style.headerLink}>
            {title}
          </Link>
        </h3>
      </div>
    </div>
  )
}

const LatestPosts = ({ ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      wpgraphql {
        posts(first: 60) {
          nodes {
            date
            title
            id
            slug
            categories {
              nodes {
                name
              }
            }
            tags {
              nodes {
                id
              }
            }
            postsLayouts {
              postObject {
                ... on WPGraphQL_Post_Postslayouts_PostObject_PostDetail {
                  mainImage {
                    ...GatsbyImageQuery
                  }
                  sectionHeader
                }
              }
            }
          }
        }
      }
    }
  `)

  const filteredPosts = filterPostsByCategories(
    data.wpgraphql.posts.nodes,
    props.category
  )

  const prioritisedPosts = prioritisePosts(filteredPosts)

  const posts = prioritisedPosts.slice(0, 3)

  return (
    <div className="bg-light py-5 py-sm-7">
      <h2
        className="h2"
        dangerouslySetInnerHTML={{ __html: props.sectionHeader }}
      />
      <Row className={`py-4 py-lg-5 mb-3 ${style.row}`}>
        {posts.map((post, index) => {
          let dt = Moment(post.date).format("D MMMM YYYY")
          let mainImage = post.postsLayouts.postObject[0].mainImage
          const { id, title, slug } = post

          return (
            <Col key={id} className=" d-flex mx-auto mb-4" sm={12} lg={4}>
              <PostCard
                title={title}
                href={`/news/${slug}`}
                dt={dt}
                mainImage={mainImage}
                slug={slug}
              />
            </Col>
          )
        })}
      </Row>
      <div className="text-center">
        <LinkButton href={props.buttonLink} title={props.buttonText} />
      </div>
    </div>
  )
}

export default memo(LatestPosts)
